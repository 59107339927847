import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import "./navbar.css";

const NavBar = () => {
	return (
		<Navbar sticky="top" bg="white" expand="lg">
			<Container fluid className="mx-5">
				<Navbar.Brand href="#home">
					<Image src="logo.png" width="55" height="35" alt="Mariash.ca Logo" />
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="ms-auto align-items-center text-left">
						<Nav.Link href="#projects">Projects</Nav.Link>
						<a
							href="mailto:hello@mariash.ca"
							className="btn outline-purple color-purple-link p-2 ps-3 pe-3 navbar-button-spacer"
						>
							Say Hello
						</a>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
};

export default NavBar;
