import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./hero.css";

const Hero = () => {
	return (
		<Container fluid className="px-5 py-5">
			<Container>
				<div className="pt-5">
					<Row className="pb-5 mt-5 mb-5">
						<Col sm={6}>
							<h1 className="fw-bolder color-purple">
								<span className="color-orange">/</span>engineering
								<span className="text-black">.</span>
							</h1>
							<h5 className="fw-normal pb-5 pt-3">
								I create and develop all kinds of applications! Starting with
								software that runs on your computer, to websites you can view in
								your browser... and I've even dabbled in building applications
								on iOS. I focus on user experience and beautiful design mixed
								with clean, functional code.
							</h5>
							<Image
								src="code.png"
								alt="code"
								className="d-none d-md-block d-block software mx-auto mt-4"
							/>
						</Col>
						<Col sm={6}>
							<Image
								src="design.png"
								alt="design"
								className="d-none d-md-block d-block software mx-auto mb-4"
							/>
							<h1 className="fw-bolder color-purple mt-5 pt-5">
								<span className="color-orange">/</span>design
								<span className="text-black">.</span>
							</h1>
							<h5 className="fw-normal pt-3">
								I started making forum skins back when those were cool. Now, I
								harness my design practice with a focus on bringing ideas to
								life. I'm probably not your typical designer as I immerse myself
								primarily in stylesheets and fluent user experiences, but I do
								dabble in other types of designing.
							</h5>
						</Col>
					</Row>
				</div>
			</Container>
		</Container>
	);
};

export default Hero;
