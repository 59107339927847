import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./about.css";

const About = () => {
	return (
		<Container fluid className="px-5 py-5" id="about">
			<Container>
				<Row className="mt-5 mb-2 justify-content-center align-items-center mx-auto">
					<Col sm={9} className="mb-5 px-0 align-middle">
						<h6 className="color-purple">Hi, my name is</h6>
						<h1 className="display-2 fw-bold">Jared Mariash.</h1>
						<h1 className="fw-normal pb-3">
							I bring ideas to life from my keyboard.
						</h1>

						<div className="pt-4">
							<a
								href="#projects"
								className="btn outline-purple px-4 py-2 btn-font-size-normal"
							>
								View my work <span className="ps-3">&#8594;</span>
							</a>
						</div>
					</Col>
					<Col
						sm={3}
						className="px-0 d-flex justify-content-center align-items-center"
					>
						<Image
							src="avatar.png"
							alt="Avatar"
							className="d-block rounded"
							fluid
						/>
					</Col>
				</Row>
			</Container>
		</Container>
	);
};

export default About;
