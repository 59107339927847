import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";

import Header from "./components/header/Header";
import NavBar from "./components/navbar/NavBar";
import About from "./components/about/About";
import Greeting from "./components/greeting/Greeting";
import Skills from "./components/skills/Skills";
import Footer from "./components/footer/Footer";
import Portfolio from "./components/portfolio/Portfolio";
import Hero from "./components/hero/hero";

function App() {
	return (
		<div className="App">
			<main>
				<Header />
				<NavBar />
				<About />
				<Greeting />
				<Skills />
				<Hero />
				<Portfolio />
				<Footer />
			</main>
		</div>
	);
}

export default App;
