import React from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";
import ListGroup from "react-bootstrap/ListGroup";
import "./skills.css";

const Skills = () => {
	return (
		<Container>
			<CardGroup className="card-hover">
				<Card className="p-5 card-shadow">
					<Card.Img
						variant="top"
						src="fullstack-development.png"
						className="card-image mt-4"
					/>
					<Card.Body>
						<Card.Title className="fw-bold">Full Stack Developer</Card.Title>
						<Card.Text>
							I code everything from scratch with simplicity and beautiful
							design in mind.
						</Card.Text>
						<Card.Text className="mt-5">
							<span className="h5 fw-normal color-purple">
								Languages I speak:
							</span>
						</Card.Text>
						<Card.Text>
							Go, Ruby, Ruby on Rails, PHP, HTML, CSS, JavaScript, React,
							Node.js, SQL, Git <span className="text-muted"> + more</span>
						</Card.Text>
						<Card.Text className="mt-5">
							<span className="h5 fw-normal color-purple">Dev Tools:</span>
						</Card.Text>
						<ListGroup variant="flush" className="mt-3">
							<ListGroup.Item>Visual Studio Code</ListGroup.Item>
							<ListGroup.Item>Intellij IDEA</ListGroup.Item>
							<ListGroup.Item>GitHub</ListGroup.Item>
							<ListGroup.Item>Terminal</ListGroup.Item>
							<ListGroup.Item>Bootstrap</ListGroup.Item>
							<ListGroup.Item>Docker</ListGroup.Item>
							<ListGroup.Item className="text-muted">
								... and more!
							</ListGroup.Item>
						</ListGroup>
					</Card.Body>
				</Card>
				<Card className="p-5 card-shadow">
					<Card.Img
						variant="top"
						src="designer.png"
						className="card-image mt-4"
					/>
					<Card.Body>
						<Card.Title className="fw-bold">Web Designer</Card.Title>
						<Card.Text>
							I value clean, simple and modern design patterns.
						</Card.Text>
						<Card.Text className="mt-5">
							<span className="h5 fw-normal color-orange">
								I enjoy designing:
							</span>
						</Card.Text>
						<Card.Text>
							UI, UX, Web, Skins, Apps, Logos{" "}
							<span className="text-muted"> + more</span>
						</Card.Text>
						<Card.Text className="mt-5">
							<span className="h5 fw-normal color-orange">Design Tools:</span>
						</Card.Text>
						<ListGroup variant="flush" className="mt-3">
							<ListGroup.Item>Adobe Photoshop</ListGroup.Item>
							<ListGroup.Item>GIMP</ListGroup.Item>
							<ListGroup.Item>Font Awesome</ListGroup.Item>
							<ListGroup.Item>Google Fonts</ListGroup.Item>
							<ListGroup.Item className="text-muted">
								... and more!
							</ListGroup.Item>
						</ListGroup>
					</Card.Body>
				</Card>
				<Card className="p-5 card-shadow">
					<Card.Img
						variant="top"
						src="mobile-development.png"
						className="card-image mt-4"
					/>
					<Card.Body>
						<Card.Title className="fw-bold">Mobile App Developer</Card.Title>
						<Card.Text>
							My newest hobby in which I create unique experiences on your
							phone.
						</Card.Text>
						<Card.Text className="mt-5">
							<span className="h5 fw-normal color-purple">
								I have played with:
							</span>
						</Card.Text>
						<Card.Text>
							Swift, SwiftUI, Kotlin, FireBase, MongoDB, CocoaPods, ARKit,
							SceneKit <span className="text-muted"> + more</span>
						</Card.Text>
						<Card.Text className="mt-5">
							<span className="h5 fw-normal color-purple">Dev Tools:</span>
						</Card.Text>
						<ListGroup variant="flush" className="mt-3">
							<ListGroup.Item>Xcode</ListGroup.Item>
							<ListGroup.Item>Android Studio</ListGroup.Item>
							<ListGroup.Item>Swift Playgrounds</ListGroup.Item>
							<ListGroup.Item>MongoDB Realm</ListGroup.Item>
							<ListGroup.Item>SF Symbols</ListGroup.Item>
							<ListGroup.Item className="text-muted">
								... and more!
							</ListGroup.Item>
						</ListGroup>
					</Card.Body>
				</Card>
			</CardGroup>
		</Container>
	);
};

export default Skills;
