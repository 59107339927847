import "./portfolio.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Github } from "react-bootstrap-icons";

import PortfolioCard from "./card/PortfolioCard";

const projects = [
	{
		id: 1,
		image: "projects/iosappar.png",
		title: "iOS AR App (WIP)",
		link: "projects/iosappar.png",
		demo: null,
	},
	{
		id: 2,
		image: "projects/developrv2.png",
		title: "developr.ca",
		link: "projects/developrv2.png",
		demo: null,
	},
	{
		id: 3,
		image: "projects/pokemart.png",
		title: "Silph Co. PokeMart",
		link: "https://github.com/HugeIRL/silphco-pokemart",
		demo: null,
	},
	{
		id: 4,
		image: "projects/trumponrails.png",
		title: "Trump Insults on Rails",
		link: "https://github.com/HugeIRL/trump-insults-on-rails",
		demo: null,
	},
	{
		id: 5,
		image: "projects/taskology.png",
		title: "Taskology",
		link: "https://github.com/HugeIRL/taskology",
		demo: null,
	},
	{
		id: 6,
		image: "projects/opendata.png",
		title: "OpenData",
		link: "projects/opendata.png",
		demo: null,
	},
];

const Portfolio = () => {
	return (
		<Container fluid className="px-5 py-5 my-5 top-border" id="projects">
			<Container>
				<h1 className="fw-bolder color-purple text-center mt-5 pt-5">
					<span className="color-orange">/</span>work
					<span className="text-black">.</span>
				</h1>
				<h5 className="fw-normal text-center pt-3 pb-5">
					Hand selected projects I've worked on in the past.
				</h5>
				<Row xs={1} md={2} className="g-4 pt-5">
					{projects.map((x) => {
						return (
							<Col key={x.id}>
								<PortfolioCard props={x} />
							</Col>
						);
					})}
				</Row>
				<Row className="pt-5 my-5 text-center">
					<Col>
						<a
							href="https://github.com/HugeIRL"
							className="btn outline-purple px-4 py-2 btn-font-size-normal"
						>
							<Github
								xmlns="http://www.w3.org/2000/svg"
								fill="currentColor"
								className="me-2 align-middle "
								size={20}
							/>
							<span className="align-middle">Find more on GitHub</span>
						</a>
					</Col>
				</Row>
			</Container>
		</Container>
	);
};

export default Portfolio;
