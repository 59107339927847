import "./card.css";
import Card from "react-bootstrap/Card";
import { ImageFill, Laptop, Github } from "react-bootstrap-icons";

const PortfolioCard = ({ props }) => {
	const { image, title } = props;

	return (
		<Card className="hover-container card-shadow">
			<Card.Img
				variant="top"
				src={image}
				alt={title}
				className="card-img-top-portfolio"
			/>
			<div className="img-overlay">
				<Card.Body className="buttons-overlay">
					<Card.Text className="d-none d-md-block text-white h5 mb-4">
						{title}
					</Card.Text>
					<Card.Text className="d-flex justify-content-center">
						{RenderButtons(props)}
					</Card.Text>
				</Card.Body>
			</div>
		</Card>
	);
};

function RenderButtons(props) {
	const demo = props.demo;
	if (demo) {
		return (
			<>
				{LinkButton(props)}
				{DemoButton(props)}
			</>
		);
	}
	return <>{LinkButton(props)}</>;
}

function DemoButton(props) {
	const demo = props.demo;
	return (
		<>
			<a href={demo} className="btn projects-card-outline-orange m-1">
				<Laptop
					xmlns="http://www.w3.org/2000/svg"
					width="20"
					height="20"
					fill="currentColor"
					viewBox="0 0 16 20"
				/>
			</a>
		</>
	);
}

function LinkButton(props) {
	return <>{IconButton(props)}</>;
}

function IconButton(props) {
	const link = props.link;
	let keyword = "github";
	let githubLink = String(link).includes(keyword);
	if (githubLink) {
		return (
			<>
				<a className="btn projects-card-outline-purple m-1" href={link}>
					<Github
						xmlns="http://www.w3.org/2000/svg"
						width="20"
						height="20"
						fill="currentColor"
						viewBox="0 0 16 20"
					/>
					<span className="ms-2">GitHub</span>
				</a>
			</>
		);
	}

	return (
		<>
			<a className="btn projects-card-outline-purple m-1" href={link}>
				<ImageFill
					xmlns="http://www.w3.org/2000/svg"
					width="20"
					height="20"
					fill="currentColor"
					viewBox="0 0 16 20"
				/>
				<span className="ms-2">Preview</span>
			</a>
		</>
	);
}

export default PortfolioCard;
