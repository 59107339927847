import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./greeting.css";

const Greeting = () => {
	return (
		<Container fluid className="bg-purple px-5 py-5 mt-5" id="about">
			<Container>
				<Row className="align-items-center my-5 py-5">
					<Col sm={5}>
						<h1 className="fw-bolder pb-4">It's nice to meet you!</h1>
						<h5 className="fw-normal pe-5">
							As a kid, I dreamed of being able to make something out of
							nothing. When I turned on our home computer for the first time, I
							was quickly sucked into the digital world of programming and web
							design and I haven't left since.
						</h5>
						<h5 className="fw-normal mt-3 mb-5 pe-5">
							I love taking an idea from start to finish on the web, watching it
							grow from something so small into a project everyone loves and
							uses. It's oddly satisfying and I think in a way I'm kind of like
							those web pages; I constantly grow into something more.
						</h5>
					</Col>
					<Col sm={7}>
						<Image src="desk.png" alt="desk" className="d-block desk mx-auto" />
					</Col>
				</Row>
				<div className="container-spacer"></div>
			</Container>
		</Container>
	);
};

export default Greeting;
