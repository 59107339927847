import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Github, Linkedin, EnvelopeHeart } from "react-bootstrap-icons";
import "./footer.css";

const Footer = () => {
	return (
		<Container fluid className="bg-purple px-5 py-5 mt-5">
			<Container>
				<footer className="text-center">
					<Image
						src="logo-white.png"
						width="55"
						height="35"
						alt="Mariash.ca Logo"
						className="my-5"
					/>
					<p className="color-transparent mb-5 h5 fw-normal">
						Forever learning, forever improving.
					</p>
					<Row>
						<Col>
							{" "}
							<a href="https://www.github.com/HugeIRL">
								<Github
									xmlns="http://www.w3.org/2000/svg"
									fill="currentColor"
									className="align-middle mx-4 my-2"
									size={35}
								/>
							</a>
							<a href="https://www.linkedin.com/in/jaredmariash">
								<Linkedin
									xmlns="http://www.w3.org/2000/svg"
									fill="currentColor"
									className=" align-middle mx-4 my-2"
									size={35}
								/>
							</a>
							<a href="mailto:hello@mariash.ca">
								<EnvelopeHeart
									xmlns="http://www.w3.org/2000/svg"
									fill="currentColor"
									className=" align-middle mx-4 my-2"
									size={35}
								/>
							</a>
						</Col>
					</Row>
					<p className="small color-transparent mb-3 mt-5">
						Lovingly created &#9829; by Jared Mariash
					</p>
				</footer>
			</Container>
		</Container>
	);
};

export default Footer;
